import { PartnerConference } from './partnerConferences/partnerConferences.types';

export const conferences: PartnerConference[] = [
  //   {
  //   name: 'SoCraTes Chile',
  //   description: 'Santiago de Chile',
  //   url: 'http://www.socrates-xconference.cl/'
  // },
  {
    name: 'Friends of Good Software',
    description: 'online from The Netherlands',
    url: 'https://frogsconf.nl',
  },
  {
    name: 'SoCraTes Canaries',
    description: 'Gran Canaria, Spain',
    url: 'http://www.socracan.org',
  },
  {
    name: 'SoCraTes Canada',
    description: 'Selenia Lodge, Québec, Canada',
    url: 'https://socrates-ca.github.io/',
  },
  {
    name: 'SoCraTes Italy',
    description: 'Rimini, Italy',
    url: 'http://www.socrates-conference.it/',
  },
  {
    name: 'SoCraTes UK',
    description: 'Dorking, England',
    url: 'http://socratesuk.org/',
  },
  {
    name: 'JS CraftCamp',
    description: 'Munich, Germany',
    url: 'http://jscraftcamp.org',
  },
  {
    name: 'SoCraTes Day Switzerland',
    description: 'Zurich, Switzerland',
    url: 'http://socrates-day.ch/',
  },
  {
    name: 'SoCraTes Day Franken',
    description: 'Nuremberg, Germany',
    url: 'https://socrates-day-franken.de/',
  },
  {
    name: 'SoCraTes Day Berlin',
    description: 'Berlin, Germany',
    url: 'https://ti.to/socratesdayberlin/2024',
  },
  {
    name: 'SoCraTes Austria',
    description: 'Linz, Austria',
    url: 'http://socrates-conference.at/',
  },
  {
    name: 'SoCraTes France',
    description: 'Rochegude, France',
    url: 'http://socrates-fr.github.io',
  },
  {
    name: 'SoCraTes Maroc',
    description: 'Bouznica, Morocco',
    url: 'https://socrates-ma.github.io',
  },
  {
    name: 'SoCraTes BE',
    description: 'La Roche-en-Ardenne, Belgium',
    url: 'http://socratesbe.org',
  },
  {
    name: 'SoCraTes Crete',
    description: 'Crete, Greece',
    url: 'https://socrates-crete.org',
  },
  {
    name: 'CodeFreeze',
    description: 'Kiilopää, Finland',
    url: 'http://www.codefreeze.fi/',
  },
  {
    name: 'SoCraTes Conference Switzerland',
    description: 'Ftan, Switzerland',
    url: 'http://socrates-ch.org/',
  },
  {
    name: 'I T.A.K.E. Unconference',
    description: 'Bucharest, Romania',
    url: 'http://itakeunconf.com/',
  },
  {
    name: 'SENECA',
    description: 'Erlangen, Germany',
    url: 'https://seneca.camp/',
  },
  {
    name: 'CITCON',
    description: 'Continuous Integration and Testing Conference',
    url: 'https://citconf.com/',
  },
];
